<template lang="pug">
modal.registration-popup(
  v-model="valueComputed.open"
  :border="false"
  :primaryButton="false"
  :secondaryButton="false"
  @input="closePopup"
)
  template(v-slot:body)
    div(v-if="registration")
      zipcode-check(
        :widget="false"
        @submit="closePopup"
        @close="closePopup"
      )
      div.registration-popup--login-link
        div.registration-popup--login-link--header
          span Heb je al een account?
        div(@click="registration = !registration").registration-popup--login-link--link Log in als bestaande klant
    div(v-else)
      login(
        @login="loginSuccess"
        @leave="closePopup"
      )
</template>

<script>
export default {
  name: 'RegistrationPopup',
  components: {
    modal: () => import('@/components/modal'),
    'zipcode-check': () => import('@/components/zipcodeCheck'),
    login: () => import('@/components/login'),
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        open: false,
        product: null,
        amount: null,
        refresh: false,
      }),
    },
  },
  data() {
    return {
      hide: false,
      registration: true,
    };
  },
  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    cart() {
      return this.$auth.user?.cart?.data || {};
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.closePopup();
    },
    closePopup() {
      this.registration = true;
      this.$emit('input', {
        open: false,
        product: null,
        amount: null,
      });
    },
    async loginSuccess() {
      if (this.valueComputed.product && this.valueComputed.amount) {
        const currentAmountSelector = this.cart.items.data.find(
          (item) => item.product_id === this.valueComputed.product,
        );

        await this.$store.dispatch('api/updateCartProduct', {
          product_id: this.valueComputed.product,
          amount: currentAmountSelector ? currentAmountSelector.amount + this.valueComputed.amount : this.valueComputed.amount,
        });
      }

      if (this.valueComputed.refresh) {
        this.$router.go({ path: this.$router.currentRoute.path, force: true });
      }

      this.closePopup();
    },
  },
};
</script>
<style lang="scss">
.registration-popup {

  h4 {
    margin-top: 0;
    font-size: 18px;
    color: $hunterGreen;
  }

  .modal-header {
    min-height: unset;
  }
  .modal-closeicon {
    top: 10px;
  }

  &--login-link {
    margin: 20px 0;
    text-align: center;

    &--header {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 15px;
      position: relative;

      span {
        background: #fff;
        position: relative;
        z-index: 1;
        padding: 0 10px;
      }

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        top: 50%;
        left: 0;
        right: 0;
        background: $lightGray;
      }
    }

    &--link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
